import { Injectable } from "@angular/core";
import { SubscribeUntilDestroyedComponent } from "app/common/subscribe-until-destroyed.component";
import { tap } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class AuthService extends SubscribeUntilDestroyedComponent {
  isLoggedIn = new BehaviorSubject<boolean>(null);

  /**
   * Constructor
   */
  constructor(private _firebaseAuth: AngularFireAuth) {
    super();

    this.subscribe(
      this._firebaseAuth.authState.pipe(
        tap((user) => {
          if (user) {
            this.isLoggedIn.next(true);
          } else {
            this.isLoggedIn.next(false);
          }
        })
      )
    );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  async signIn(email: string, password: string): Promise<any> {
    await this._firebaseAuth.setPersistence("session");
    return this._firebaseAuth.signInWithEmailAndPassword(email, password);
  }

  /**
   * Sign out
   */
  async signOut(): Promise<any> {
    // Set the authenticated flag to false
    return await this._firebaseAuth.signOut();
  }

  handleResetPassword(actionCode: string): Promise<any> {
    return this._firebaseAuth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        const accountEmail = email;
        return email;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  confirmPasswordReset(actionCode: string, newPassword: string): Promise<any> {
    return this._firebaseAuth
      .confirmPasswordReset(actionCode, newPassword)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
}
