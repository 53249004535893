import { Component, OnInit } from "@angular/core";
import { SubscribeUntilDestroyedComponent } from "app/common/subscribe-until-destroyed.component";
import { Testimonial } from "app/models/testimonial";
import { TestimonialService } from "app/services/testimonials.service";
import { tap } from "rxjs/internal/operators/tap";
import { faStar } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-testimonials",
  templateUrl: "./testimonials.component.html",
  styleUrls: ["./testimonials.component.scss"],
})
export class TestimonialsComponent
  extends SubscribeUntilDestroyedComponent
  implements OnInit
{
  reviews: Testimonial[];
  faFire = faStar;

  state = true;

  constructor(private _testimonialService: TestimonialService) {
    super();
  }

  ngOnInit() {
    //Load testimonials
    this.subscribe(
      this._testimonialService.getTestimonials().pipe(
        tap((reviews: Testimonial[]) => {
          this.reviews = reviews;
        })
      )
    );
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }
}
