import { Component, OnInit } from "@angular/core";
import { Form, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "app/services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  focus;
  focus1;

  signInForm: FormGroup;

  error: string = "";

  //Invite user form us form
  showAlert: boolean = false;
  sendingMessage: boolean = false;
  alert = {};

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _router: Router
  ) {}

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("login-page");
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.add("navbar-transparent");

    // Create the form
    this.signInForm = this._formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }
  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-page");
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.remove("navbar-transparent");
  }

  login(): Promise<void> {
    if (!this.signInForm.valid) {
      console.log("Cannot login - form is not valid!");
      return;
    }

    this._authService
      .signIn(this.signInForm.value.email, this.signInForm.value.password)
      .then(
        (value) => {
          // Reset the form for later use
          this.signInForm.reset();
          //Redirect to admin page.
          this._router.navigateByUrl("/invite");
        },
        (error) => {
          this.onLoginError(error);
        }
      )
      .catch((error) => {
        this.onLoginError(error);
      });
  }

  onLoginError(error) {
    console.log(error);

    // Check the error type
    if (
      (error &&
        (error.code === "auth/wrong-password" ||
          error.code === "auth/user-not-found")) ||
      error.code === "auth/user-disabled" ||
      error.code === "permission-denied"
    ) {
      this.error =
        "We did not find an account matching the email address and password you have entered";
    } else if (error && error.code === "auth/invalid-email") {
      this.error = "The email address entered is not valid";
    } else if (error && error.code === "auth/too-many-requests") {
      this.error =
        "You have made too many unsuccessful attempts to sign in. Please try again later";
    } else {
      this.error =
        "We are sorry, but an unexpected problem occurred whilst attempting to sign you in to your account";
    }
    this.showAlert = true;
    this.alert = {
      id: 4,
      type: "danger",
      strong: "Ooops!",
      message: this.error,
      icon: "objects_support-17",
    };
  }
}
