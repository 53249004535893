import { Component, OnInit } from "@angular/core";
import { AngularFireStorageReference } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SubscribeUntilDestroyedComponent } from "app/common/subscribe-until-destroyed.component";
import { Invite } from "app/models/invite";
import { Testimonial } from "app/models/testimonial";
import { StorageService } from "app/services/storage.service";
import { TestimonialService } from "app/services/testimonials.service";
import { finalize, tap } from "rxjs/operators";

@Component({
  selector: "app-submit-testimonial",
  templateUrl: "./submit-testimonial.component.html",
  styleUrls: ["./submit-testimonial.component.css"],
})
export class SubmitTestimonialComponent
  extends SubscribeUntilDestroyedComponent
  implements OnInit
{
  constructor(
    private _testimonialService: TestimonialService,
    private _activeRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _storageService: StorageService
  ) {
    super();
  }

  inviteId: string;

  //Contact us form
  showAlert: boolean = false;
  sendingMessage: boolean = false;
  form: FormGroup;
  alert = {};
  submitted: boolean = false;

  //Notifications when triggering contact us
  successAlert = {
    id: 1,
    type: "success",
    strong: "Review Submitted",
    message: "Thank you for submitting your review.",
    icon: "ui-2_like",
  };

  errorAlert = {
    id: 4,
    type: "danger",
    strong: "Ooops!",
    message: "Error submitting review, try again later.",
    icon: "objects_support-17",
  };

  unknownInvite = {
    id: 4,
    type: "danger",
    strong: "Ooops!",
    message:
      "Unable to submit review. This link is either invalid or has already been used.",
    icon: "objects_support-17",
  };

  invite: Invite;

  images: any[] = [];

  loading: boolean = false;

  uploadingImages: boolean = false;

  ngOnInit(): void {
    this.loading = true;
    this._activeRoute.params.subscribe((params) => {
      this.inviteId = params["id"];
      this.subscribe(
        this._testimonialService.getInvite(this.inviteId).pipe(
          tap((invite) => {
            this.invite = invite;
            this.loading = false;
            if (!invite) {
              console.log(`Invite does not exist, unable to submit one.`);
              this.showAlert = true;
              this.alert = this.unknownInvite;
            } else {
              // Initialise form
              this.form = this._formBuilder.group({
                name: [
                  this.invite.name,
                  Validators.compose([Validators.required]),
                ],
                message: ["", Validators.compose([Validators.required])],
              });
            }
          })
        )
      );
    });
  }

  imageUpload(noImage: number, image: any) {
    console.log(`Image ${noImage} uploaded`);
    switch (noImage) {
      case 1:
      case 2:
      case 3:
        const uploaded = {
          id: noImage,
          image: image,
          uploading: true,
        };

        this.images.push(uploaded);

        this.handleFileUpload(image, noImage);

        break;
      default:
        console.log(`Unknown image ${noImage}`);
    }
  }

  imageRemoved(noImage: number) {
    console.log(`Image ${noImage} removed`);
    switch (noImage) {
      case 1:
      case 2:
      case 3:
        this.images.splice(
          this.images?.findIndex((_) => _.id === noImage),
          1
        );
        break;
      default:
        console.log(`Unknown image ${noImage}`);
    }
  }

  closeAlert() {
    this.showAlert = false;
  }

  async submitReview() {
    if (!this.form.valid) {
      console.error(`Form is invalid`);
    }

    this.sendingMessage = true;

    const images =
      this?.images?.map((_) => {
        return _?.url;
      }) ?? null;

    const testimonial: Testimonial = {
      message: this.form.value.message,
      name: this.form.value.name,
      live: true,
      rating: 5,
      submitted: new Date(),
      images: images,
    };

    console.log(`Total images uploaded are ${this.images?.length}`);

    this._testimonialService
      .submitTestimonial(this.inviteId, testimonial)
      .then((success) => {
        this.showAlert = true;
        this.submitted = true;
        this.alert = this.successAlert;
      })
      .catch((err) => {
        console.error(`Error submitting testimonial`, err);
        this.showAlert = true;
        this.alert = this.errorAlert;
        this.sendingMessage = false;
      });
  }

  async handleFileUpload(file: File, index: number) {
    if (!file) {
      return;
    }

    const fileName = `${btoa(file.name)}`;
    let meta = { name: fileName, mime: file.type };
    let fileRef: AngularFireStorageReference = null;
    try {
      fileRef = await this._storageService.getFileUploadReference(
        "reviews",
        this.inviteId,
        meta
      );
    } catch (err) {
      console.error(`Error getting file upload reference`, err);
      return;
    }

    try {
      const uploadTask = this._storageService.fileUpload(file, fileRef, meta);

      this.subscribe(
        uploadTask.percentageChanges().pipe(
          tap(async (percentage) => {
            console.log(`Percentage upload is ${percentage}`);

            this.uploadingImages = true;
            if (percentage === 100) {
              console.log(`Successfully uploaded file`);
            }
          })
        )
      );

      uploadTask
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((url: string) => {
              console.log(`Uploading file`);
              let i = this.images.findIndex((_) => _.id === index);
              let image = this.images[i];
              image.uploading = false;
              image.url = url;
              this.images[i] = image;

              //See if any other images still uploading
              if (this.images?.findIndex((_) => _.uploading === true)) {
                this.uploadingImages = false;
              }
            });
          })
        )
        .subscribe();
    } catch (err) {
      console.error(`Error uploading file`, err);
    }
  }
}
