import { Component, OnInit } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SubscribeUntilDestroyedComponent } from "app/common/subscribe-until-destroyed.component";
import { Invite } from "app/models/invite";
import { TestimonialService } from "app/services/testimonials.service";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-invite-customer",
  templateUrl: "./invite-customer.component.html",
  styleUrls: ["./invite-customer.component.css"],
})
export class InviteCustomerComponent
  extends SubscribeUntilDestroyedComponent
  implements OnInit
{
  invitedUsers: Invite[];

  form: FormGroup;

  //Invite user form us form
  showAlert: boolean = false;
  sendingMessage: boolean = false;
  alert = {};

  //Notifications when triggering contact us
  successAlert = {
    id: 1,
    type: "success",
    strong: "Invite sent!",
    message: "Successfully sent invite to customer",
    icon: "ui-2_like",
  };

  errorAlert = {
    id: 4,
    type: "danger",
    strong: "Ooops!",
    message: "Error sending invite, try again later.",
    icon: "objects_support-17",
  };

  constructor(
    private _formBuilder: FormBuilder,
    private _testimonialService: TestimonialService,
    private fireFunctions: AngularFireFunctions
  ) {
    super();
  }

  ngOnInit(): void {
    // Create the form
    this.form = this._formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      name: ["", Validators.required],
    });

    //Get all existing invites
    this.subscribe(
      this._testimonialService.getInvites().pipe(
        tap((invites) => {
          this.invitedUsers = invites;
        })
      )
    );
  }

  /**
   * Send testimonial invite to client
   */
  sendInvite() {
    if (!this.form.valid) {
      return;
    }

    const invite: Invite = {
      name: this.form.value.name ?? null,
      email: this.form.value.email ?? null,
      invited: new Date(),
      submitted: false,
    };

    this.sendingMessage = true;

    //Send invite to customer.
    this._testimonialService
      .inviteCustomer(invite)
      .then((success) => {
        console.log(`Successfully sent email`);

        this.sendingMessage = true;

        const sendInvite = this.fireFunctions.httpsCallable(
          "sendTestimonialEmail"
        );

        sendInvite({
          name: this.form.value.name,
          email: this.form.value.email,
          id: `${success.id}`,
        })
          .toPromise()
          .then((sent) => {
            this.sendingMessage = false;
            this.showAlert = true;
            this.alert = this.successAlert;
            this.form.reset();
          })
          .catch((err) => {
            console.error(`Error sending email`, err);
            this.sendingMessage = false;
            this.showAlert = true;
            this.alert = this.errorAlert;
          });
      })
      .catch((err) => {
        console.error(`Error sending email`, err);
        this.sendingMessage = false;
        this.showAlert = true;
        this.alert = this.errorAlert;
      });
  }

  closeAlert() {
    this.showAlert = false;
  }

  removeInvite(inviteId: string) {
    this._testimonialService
      .deleteInvite(inviteId)
      .then((subcess) => {
        console.log(`Successfully deleted invite`);
      })
      .catch((err) => {
        console.error(`Error deleting invite`, err);
      });
  }
}
