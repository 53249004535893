import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AngularFireFunctions } from "@angular/fire/functions";
import {
  faFire,
  faTemperatureArrowUp,
  faShower,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { SubscribeUntilDestroyedComponent } from "app/common/subscribe-until-destroyed.component";
import { TestimonialService } from "app/services/testimonials.service";
import { tap } from "rxjs/operators";
import { Testimonial } from "app/models/testimonial";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent
  extends SubscribeUntilDestroyedComponent
  implements OnInit
{
  constructor(
    private fireFunctions: AngularFireFunctions,
    private formBuilder: FormBuilder,
    private _testimonialService: TestimonialService
  ) {
    super();
  }

  //Contact us form
  showAlert: boolean = false;
  sendingMessage: boolean = false;
  form: FormGroup;
  alert = {};

  //Notifications when triggering contact us
  successAlert = {
    id: 1,
    type: "success",
    strong: "Message sent!",
    message:
      "Thank you for your message. We will get back to you as soon as possible.",
    icon: "ui-2_like",
  };

  errorAlert = {
    id: 4,
    type: "danger",
    strong: "Ooops!",
    message: "Error sending message, try again later.",
    icon: "objects_support-17",
  };

  //Font awesome icons
  faFire = faFire;
  faTemperatureArrowUp = faTemperatureArrowUp;
  faShower = faShower;
  faAngleRight = faAngleRight;

  //Date Josh experience starts from
  startingDate = 2008;
  yearsExperience = new Date().getFullYear() - this.startingDate;

  reviews: Testimonial[];

  ngOnInit() {
    //Load testimonials
    this.subscribe(
      this._testimonialService.getTestimonials(3).pipe(
        tap((reviews: Testimonial[]) => {
          this.reviews = reviews;
        })
      )
    );

    // Initialise form
    this.form = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required])],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      message: ["", Validators.compose([Validators.required])],
    });
  }

  closeAlert() {
    this.showAlert = false;
  }

  sendEmail() {
    if (!this.form.valid) {
      console.log(`Invalid form`);
      return;
    }
    this.sendingMessage = true;
    const sendEmail = this.fireFunctions.httpsCallable("sendEmail");
    sendEmail({
      name: this.form.value.name,
      email: this.form.value.email,
      message: this.form.value.message,
    })
      .toPromise()
      .then((success) => {
        console.log(`Successfully send email`);
        this.sendingMessage = false;
        this.showAlert = true;
        this.alert = this.successAlert;
        this.form.reset();
      })
      .catch((err) => {
        console.error(`Error sending email`, err);
        this.sendingMessage = false;
        this.showAlert = true;
        this.alert = this.errorAlert;
      });
  }
}
