import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { InviteCustomerComponent } from "./invite-customer/invite-customer.component";
import { TestimonialsComponent } from "./testimonials.component";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { SubmitTestimonialComponent } from "./submit-testimonial/submit-testimonial.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ImageUploadModule } from "app/shared/image-upload/image-upload.module";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";

const routes: Routes = [
  {
    path: "invite",
    pathMatch: "full",
    component: InviteCustomerComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: "testimonials/invite/:id",
    pathMatch: "full",
    component: SubmitTestimonialComponent,
  },
  { path: "testimonials", pathMatch: "full", component: TestimonialsComponent },
];

@NgModule({
  declarations: [SubmitTestimonialComponent],
  imports: [
    CommonModule,
    NgbModule,
    JwBootstrapSwitchNg2Module,
    FormsModule,
    ReactiveFormsModule,
    ImageUploadModule,
    RouterModule.forChild(routes),
  ],
})
export class TestimonialsModule {}
