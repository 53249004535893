import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { LandingComponent } from "./sections/jmp/landing/landing.component";
import { LoginComponent } from "./examples/login/login.component";
import { AdminModule } from "./sections/admin/admin.module";
import { TestimonialsModule } from "./sections/testimonials/testimonials.module";

const routes: Routes = [
  { path: "", pathMatch: "full", component: LandingComponent },
  {
    path: "login",
    component: LoginComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AdminModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: "enabled",
    }),
    TestimonialsModule,
  ],
  exports: [],
})
export class AppRoutingModule {}
