import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SectionsComponent } from "./sections.component";
import { HeadersComponent } from "./headers/headers.component";
import { FeaturesComponent } from "./features/features.component";
import { BlogsComponent } from "./blogs/blogs.component";
import { TeamsComponent } from "./teams/teams.component";
import { ProjectsComponent } from "./projects/projects.component";
import { PricingComponent } from "./pricing/pricing.component";
import { TestimonialsComponent } from "./testimonials/testimonials.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { LandingComponent } from "./jmp/landing/landing.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LoginComponent } from "app/examples/login/login.component";
import { ProfileComponent } from "app/examples/profile/profile.component";
import { InviteCustomerComponent } from "./testimonials/invite-customer/invite-customer.component";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
  ],
  declarations: [
    SectionsComponent,
    HeadersComponent,
    FeaturesComponent,
    BlogsComponent,
    TeamsComponent,
    ProjectsComponent,
    PricingComponent,
    TestimonialsComponent,
    ContactsComponent,
    LoginComponent,
    LandingComponent,
    InviteCustomerComponent,
  ],
  exports: [SectionsComponent, LandingComponent],
})
export class SectionsModule {}
