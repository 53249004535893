import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app.routing";
import { SectionsModule } from "./sections/sections.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";

import { PresentationModule } from "./presentation/presentation.module";
import { AngularFireModule } from "@angular/fire";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { environment } from "environments/environment";
import { AuthService } from "./services/auth.service";
import { TestimonialService } from "./services/testimonials.service";

@NgModule({
  declarations: [AppComponent, NavbarComponent],
  imports: [
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    PresentationModule,
    SectionsModule,
    // ComponentsModule,
    //ExamplesModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [AuthService, TestimonialService],
  bootstrap: [AppComponent],
})
export class AppModule {}
