import { Injectable } from "@angular/core";
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(private _storage: AngularFireStorage) {}

  getFileUploadReference(
    folderName: string,
    clientId: string,
    meta: any = {}
  ): any {
    const filePath = `${folderName}/${clientId}/${meta.name}`;
    const fileRef = this._storage.ref(filePath);
    return fileRef;
  }

  fileUpload(
    file: any,
    fileRef: AngularFireStorageReference,
    meta: any = {}
  ): AngularFireUploadTask {
    return fileRef.put(file, meta);
  }

  encodedFileUpload(baseString: string, clientId: string, meta: any = {}) {
    try {
      // one image
      const filePath = `clients/${clientId}/${meta.fileName}`;
      const fileRef = this._storage.ref(filePath);
      const uploadTask = fileRef.putString(baseString, "base64", meta);

      // return upload task for percentage changes
      return uploadTask;
    } catch (e) {
      console.error(e.message);
    }
  }

  pauseFileUpload(task: any) {
    try {
      return task.pause().then(() => {
        console.info("Upload paused");
      });
    } catch (e) {
      console.error(e.message);
    }
  }

  resumeFileUpload(task: any) {
    try {
      return task.resume().then(() => {
        console.info("Upload resumed");
      });
    } catch (e) {
      console.error(e.message);
    }
  }

  cancelFileUpload(task: any) {
    try {
      return task.cancel().then(() => {
        console.info("Upload cancelled");
      });
    } catch (e) {
      console.error(e.message);
    }
  }

  getUploadProgress(task: any): Observable<number> {
    // emits upload completion percentage observable
    try {
      return task.percentageChanges();
    } catch (e) {
      console.error(e.message);
    }
  }

  notifyUploadCompleted(task: any, fileRef: any): Observable<string> {
    // emits when upload has completed
    try {
      return task.snapshotChanges().pipe(
        finalize(() => {
          return fileRef.getDownloadURL();
        })
      );
    } catch (e) {
      console.error(e.message);
    }
  }

  fileDownload(filePath: string): Observable<string | null> {
    // emits an observable containing download URL
    // pipe available for displaying images
    // * <img [src]="filePath | getDownloadURL" />
    try {
      const ref = this._storage.refFromURL(filePath);
      return ref.getDownloadURL();
    } catch (e) {
      console.error(e.message);
    }
  }

  getFileMetadata(fileRef: string): Observable<any> {
    // returns an observable
    try {
      const ref = this._storage.refFromURL(fileRef);
      // console.log(ref);
      return ref.getMetadata();
    } catch (e) {
      console.error(e.message);
    }
  }
  deleteFile(url: string): Promise<any> {
    return this._storage.storage.refFromURL(url).delete();
  }
}
