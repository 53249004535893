import { Injectable } from "@angular/core";
import { SubscribeUntilDestroyedComponent } from "app/common/subscribe-until-destroyed.component";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Testimonial } from "app/models/testimonial";
import { map } from "rxjs/operators";
import { Invite } from "app/models/invite";

@Injectable()
export class TestimonialService extends SubscribeUntilDestroyedComponent {
  constructor(private _firestore: AngularFirestore) {
    super();
  }

  getInvite(inviteId): Observable<Invite> {
    return this._firestore
      .collection("invites")
      .doc<Invite>(inviteId)
      .snapshotChanges()
      .pipe(
        map(
          (a: any) => {
            const data = a?.payload?.data();
            const id = a?.payload?.id;

            if (!data) {
              return null;
            }

            const invite = { id, ...data } as Invite;
            return invite;
          },
          (error) => {
            console.log("An error occured: ", error);
          }
        )
      );
  }

  getTestimonials(limit: number = 100): Observable<Testimonial[]> {
    return this._firestore
      .collection("testimonials", (ref) =>
        ref.orderBy("submitted", "desc").limit(limit)
      )
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          const testimonials: Testimonial[] = snaps.map((snap) => {
            const doc = snap.payload.doc;
            const testimonial: Partial<Testimonial> = doc.data();

            return {
              submitted: testimonial?.submitted?.toDate() || null,
              name: testimonial.name,
              rating: testimonial.rating ?? 0,
              message: testimonial.message,
              live: testimonial.live ?? false,
              images: testimonial?.images ?? null,
            };
          });
          return testimonials;
        })
      );
  }

  getInvites(): Observable<Invite[]> {
    return this._firestore
      .collection("invites", (ref) => ref.orderBy("invited", "desc"))
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          const invites: Invite[] = snaps.map((snap) => {
            const doc = snap.payload.doc;
            const invite: Partial<Invite> = doc.data();

            return {
              id: doc.id,
              submitted: invite.submitted || null,
              name: invite.name,
              email: invite.email,
              invited: invite.invited ?? null,
            };
          });
          return invites;
        })
      );
  }

  deleteInvite(inviteId: string) {
    return this._firestore.collection("invites").doc(inviteId).delete();
  }

  inviteCustomer(invite: Invite) {
    return this._firestore.collection("invites").add(invite);
  }

  submitTestimonial(inviteId: string, testimonial: Testimonial) {
    const batch = this._firestore.firestore.batch();

    const testimonialRef = this._firestore.collection("testimonials").doc().ref;
    const inviteRef = this._firestore.collection("invites").doc(inviteId).ref;

    batch.set(testimonialRef, testimonial, { merge: true });
    batch.delete(inviteRef);

    return batch.commit();
  }
}
